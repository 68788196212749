import React from "react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { theme } from "./styles/theme";
import "overlayscrollbars/overlayscrollbars.css";
import { inputGlobalStyles, root } from "shared/constGlobal";
import { CookiesProvider } from "react-cookie";
import { CssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import App from "./app";

root.render(
  <ThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <CookiesProvider>
          <CssBaseline />
          {inputGlobalStyles}
          <App />
        </CookiesProvider>
      </BrowserRouter>
    </StyledEngineProvider>
  </ThemeProvider>,
);
serviceWorkerRegistration.register();
