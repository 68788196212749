import React, { lazy, Suspense } from "react";
import { styled } from "@mui/system";
import {
  Typography,
  Card,
  CardHeader,
  CardMedia,
  Paper,
  IconButton,
  Box,
  SpeedDial,
  Grid,
  Skeleton,
  Backdrop,
  ImageListItemBar,
  Avatar,
  Chip,
  CardContent,
  CardActions,
  ImageListItem,
} from "@mui/material";
import { TabList } from "@mui/lab";
import Carousel from "react-material-ui-carousel";
import CircleIcon from "@mui/icons-material/Circle";
import { theme } from "styles/theme";
import Tab, { tabClasses } from "@mui/material/Tab";
import Tabs, { tabsClasses } from "@mui/material/Tabs";

export const AboutImageSkeleton = styled(Skeleton)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    width: 293,
    height: 152,
  },
  [theme.breakpoints.up("md")]: {
    width: 586,
    height: 304,
  },
  [theme.breakpoints.up("lg")]: {
    width: 879,
    height: 456,
  },
}));

export const AboutTextSkeleton = styled(Skeleton)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: 750,
    height: 10,
  },
  [theme.breakpoints.up("md")]: {
    width: 850,
    height: 20,
  },
}));

export const AboutImageBox = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  borderRadius: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    width: 293,
    height: 152,
  },
  [theme.breakpoints.up("md")]: {
    width: 586,
    height: 304,
  },
  [theme.breakpoints.up("lg")]: {
    width: 879,
    height: 456,
  },
}));

export const AboutImage = styled("img")(({ theme }) => ({
  borderRadius: theme.spacing(2),
  transition: "transform .4s",
  "&:hover": {
    transform: "scale(1.05)",
    cursor: "pointer",
    filter: "brightness(115%)",
  },
  [theme.breakpoints.down("md")]: {
    width: 293,
    height: 152,
  },
  [theme.breakpoints.up("md")]: {
    width: 586,
    height: 304,
  },
  [theme.breakpoints.up("lg")]: {
    width: 879,
    height: 456,
  },
}));

export const AboutBackdropImage = styled("img")(({ theme }) => ({
  borderRadius: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    width: 586,
    height: 304,
  },
  [theme.breakpoints.up("md")]: {
    width: 879,
    height: 456,
  },
  [theme.breakpoints.up("lg")]: {
    width: 1172,
    height: 608,
  },
  [theme.breakpoints.up("xl")]: {
    width: 1465,
    height: 760,
  },
}));

export const AboutTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    fontSize: 15,
  },
}));

export const AboutBox = styled(Box)(({ theme }) => ({
  width: 850,
}));

export const AboutBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 4,
  backgroundColor: "black",
}));

export const AboutImageListItemBar = styled(ImageListItemBar)(() => ({
  background: "none",
}));

export const AboutIconButton = styled(IconButton)(({ theme }) => ({
  color: "#ffffff",
  margin: theme.spacing(2, 2),
  border: "3px solid #0084ff",
  backgroundColor: theme.palette.tertiary.main,
  "&:hover": {
    border: "3px solid #02d9fc",
    backgroundColor: "#005cb2",
  },
}));

export const CareersCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: 200,
  borderRadius: theme.spacing(2),
}));

export const CareersBadgeSkeleton = styled(Skeleton)(({ theme }) => ({
  marginTop: 25,
  [theme.breakpoints.down("md")]: {
    width: 64,
    height: 64,
  },
  [theme.breakpoints.up("md")]: {
    width: 128,
    height: 128,
  },
}));

export const CareersTypographySkeleton = styled(Skeleton)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: 15,
  },
}));

export const CareersAvatar = styled(Avatar)(({ theme }) => ({
  marginTop: 25,
  pointerEvents: "none",
  [theme.breakpoints.down("md")]: {
    width: 64,
    height: 64,
  },
  [theme.breakpoints.up("md")]: {
    width: 128,
    height: 128,
  },
}));

export const CareersTypography = styled(Typography)(() => ({
  fontWeight: "600",
}));

const About = lazy(() => import("subcomponents/Desktop/about"));

const Resources = lazy(() => import("subcomponents/Desktop/pressKit"));

const Press = lazy(() => import("subcomponents/Desktop/articles"));

const Careers = lazy(() => import("subcomponents/Desktop/careers"));

const Video = lazy(() => import("subcomponents/Desktop/video"));

const Gallery = lazy(() => import("subcomponents/Desktop/gallery"));

const Buttons = lazy(() => import("subcomponents/Desktop/buttons"));

export const CompanyTab = styled(Tab)(({ theme }) => ({
  textTransform: "initial",
  margin: theme.spacing(0, 2),
  minWidth: 0,
  fontWeight: "600",
  letterSpacing: 0.5,
  color: "#fff",
  borderRadius: theme.spacing(1),
  [`&.${tabClasses.selected}`]: {
    color: "#fff",
  },
  [theme.breakpoints.down("md")]: {
    margin: theme.spacing(0, 0.5),
  },
}));

export const CompanyTabList = styled(TabList)(({ theme }) => ({
  width: "100%",
  borderRadius: theme.spacing(2, 2, 0, 0),
  padding: "10px",
  "& .MuiTouchRipple-root": {
    color: theme.palette.primary.light,
  },
  [`& .${tabsClasses.indicator}`]: {
    height: "100%",
    borderRadius: "8px",
    backgroundColor: "rgba(255, 255, 255, .2)",
  },
  [theme.breakpoints.down("md")]: {
    padding: "unset",
  },
}));

export const companyTabPanels = (setOverflow) => [
  {
    tabPanelId: "about",
    content: (
      <Suspense fallback={null}>
        <About setOverflow={setOverflow} />
      </Suspense>
    ),
  },
  {
    tabPanelId: "articles",
    content: (
      <Suspense fallback={null}>
        <Press />
      </Suspense>
    ),
  },
  {
    tabPanelId: "presskits",
    content: (
      <Suspense fallback={null}>
        <Resources />
      </Suspense>
    ),
  },
  {
    tabPanelId: "careers",
    content: (
      <Suspense fallback={null}>
        <Careers />
      </Suspense>
    ),
  },
  {
    tabPanelId: "gallery",
    content: (
      <Suspense fallback={null}>
        <Video setOverflow={setOverflow} />
      </Suspense>
    ),
  },
  {
    tabPanelId: "videos",
    content: (
      <Suspense fallback={null}>
        <Gallery setOverflow={setOverflow} />
      </Suspense>
    ),
  },
];

export const cmsTabPanels = [
  {
    tabPanelId: "1",
    content: (
      <Suspense fallback={null}>
        <Buttons />
      </Suspense>
    ),
  },
  {
    tabPanelId: "2",
    content: (
      <Suspense fallback={null}>
        <Press />
      </Suspense>
    ),
  },
  {
    tabPanelId: "3",
    content: (
      <Suspense fallback={null}>
        <Resources />
      </Suspense>
    ),
  },
  {
    tabPanelId: "4",
    content: (
      <Suspense fallback={null}>
        <Careers />
      </Suspense>
    ),
  },
  {
    tabPanelId: "5",
    content: (
      <Suspense fallback={null}>
        <Video />
      </Suspense>
    ),
  },
  {
    tabPanelId: "6",
    content: (
      <Suspense fallback={null}>
        <Gallery />
      </Suspense>
    ),
  },
];

export const GalleryCardMedia = styled(CardMedia)(() => ({
  height: "100%",
  overflow: "hidden",
  position: "relative",
  cursor: "pointer",
  transition: "transform .4s",
  "&:hover": {
    zIndex: 1,
    transform: "scale(1.05)",
    filter: "brightness(115%)",
  },
}));

export const GalleryCard = styled(Card)(() => ({
  height: 425,
  position: "relative",
  backgroundColor: "transparent",
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
}));

export const GalleryGrid = styled(Grid)(() => ({
  height: "100%",
  position: "relative",
}));

export const GalleryCarousel = styled(Carousel)(() => ({
  width: 850,
  borderRadius: theme.spacing(2),
}));

export const GalleryCarouselSkeleton = styled(Skeleton)(() => ({
  height: 425,
  width: 850,
  top: 150,
  borderRadius: theme.spacing(2),
}));

export const GalleryBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 4,
  backgroundColor: "black",
}));

export const GalleryBackdropImage = styled("img")(({ theme }) => ({
  borderRadius: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    width: 586,
    height: 304,
  },
  [theme.breakpoints.up("md")]: {
    width: 879,
    height: 456,
  },
  [theme.breakpoints.up("lg")]: {
    width: 1172,
    height: 608,
  },
  [theme.breakpoints.up("xl")]: {
    width: 1465,
    height: 760,
  },
}));

export const GalleryImageListItemBar = styled(ImageListItemBar)(() => ({
  background: "none",
}));

export const GlobalIconButton = styled(IconButton)(({ theme }) => ({
  color: "#ffffff",
  margin: theme.spacing(2, 2),
  border: "3px solid #0084ff",
  backgroundColor: theme.palette.tertiary.main,
  "&:hover": {
    border: "3px solid #02d9fc",
    backgroundColor: "#005cb2",
  },
}));

export const CMSBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: 25,
}));

export const GameCardGrid = styled(Grid)(() => ({
  height: 75,
}));

export const GameCardMedia = styled(CardMedia)(() => ({
  pointerEvents: "none",
  width: 540,
  height: 252,
}));

export const GameCard = styled(Card)(({ theme }) => ({
  width: 540,
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
}));

export const CompanyBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(20),
  marginBottom: theme.spacing(5),
  borderRadius: theme.spacing(2),
  height: 935,
  width: 1340,
  backgroundColor: theme.palette.primary.dark,
  [theme.breakpoints.down("sm")]: {
    fontSize: 1040,
  },
}));

export const GameCardAvatar = styled(Avatar)(() => ({
  transition: "0.3s",
  pointerEvents: "none",
  width: 100,
  height: 100,
  borderRadius: "1rem",
  border: "5px solid #2f4e6b",
  bottom: 50,
}));

export const GameCardTitleTypography = styled(Typography)(({ theme }) => ({
  position: "relative",
  fontWeight: 600,
  [theme.breakpoints.up("md")]: {
    fontSize: 20,
  },
  bottom: 70,
  left: 125,
  marginBottom: -30,
}));

export const GameCardChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  [theme.breakpoints.up("md")]: {
    fontSize: 15,
  },
}));

export const GameCardContent = styled(CardContent)(({ theme }) => {
  return {
    backgroundColor: theme.palette.primary.main,
    padding: "1rem 1.5rem 1.5rem",
    height: 250,
  };
});

export const GameCardDescriptionTypography = styled(Typography)(
  ({ theme }) => ({
    [theme.breakpoints.up("md")]: {
      fontSize: 15,
    },
  }),
);

export const GameCardSocialActions = styled(CardActions)(() => ({
  backgroundColor: theme.palette.primary.dark,
  height: 64,
}));

export const GameCardActions = styled(CardActions)(() => ({
  backgroundColor: theme.palette.primary.main,
}));

export const GameCardPriceBox = styled(Box)(({ theme }) => ({
  width: 82,
  height: 42,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 3,
  backgroundColor: "#4caf50",
}));

export const GameCardPriceTypography = styled(Typography)(({ theme }) => ({
  color: "#fafafa",
  [theme.breakpoints.up("md")]: {
    fontSize: 15,
  },
}));

export const GameCardSpeedDial = styled(SpeedDial)(() => ({
  "&  .MuiSpeedDial-fab": {
    height: 48,
    width: 48,
    "& svg": {
      display: "block",
    },
  },
  "&  .MuiSpeedDialAction-fab": {
    "& svg": {
      display: "block",
    },
  },
}));

export const GameCardSteamTypography = styled(Typography)(({ theme }) => ({
  color: "#fafafa",
  [theme.breakpoints.up("md")]: {
    fontSize: 15,
  },
}));

export const GameCardAvatarSkeleton = styled(Skeleton)(() => ({
  bottom: 50,
  borderRadius: "1rem",
  border: "5px solid #2f4e6b",
}));

export const GameCardChipSkeleton = styled(Skeleton)(() => ({
  width: 90,
  height: 30,
  borderRadius: 15,
}));

export const GameCardMediaSkeleton = styled(Skeleton)(() => ({
  height: 252,
}));

export const MiniCard = styled(Card)(({ theme }) => ({
  margin: "auto",
  borderRadius: theme.spacing(2),
  transition: "0.3s",
  width: 415,
  height: 400,
  marginLeft: "auto",
  overflow: "initial",
  background: theme.palette.primary.main,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingBottom: theme.spacing(2),

  [theme.breakpoints.up("xl")]: {
    width: 600,
    height: 222,
    flexDirection: "row",
    paddingTop: theme.spacing(2),
  },
}));

export const MiniCardMedia = styled(CardMedia)(({ theme }) => ({
  width: 375,
  height: 173,
  marginTop: 25,
  borderRadius: theme.spacing(2),
  marginLeft: theme.spacing(2.5),
  transform: "translateX(-8px)",
  pointerEvents: "none",
  [theme.breakpoints.up("xl")]: {
    marginLeft: theme.spacing(3.5),
    marginTop: 0,
    width: 225,
    height: 180,
  },
}));

export const MediaOverlayImage = styled("img")(({ theme }) => ({
  width: 375,
  marginBottom: -150,
  marginLeft: theme.spacing(3.5),
  borderBottomLeftRadius: theme.spacing(2),
  borderBottomRightRadius: theme.spacing(0),
  transform: "translateX(-8px)",
  position: "absolute",
  pointerEvents: "none",
  [theme.breakpoints.up("md")]: {
    width: 150,
    marginTop: 111,
    marginRight: theme.spacing(28.9),
    borderBottomLeftRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(0),
  },
  [theme.breakpoints.up("xl")]: {
    width: 205,
    marginTop: -8,
    marginRight: theme.spacing(28.2),
    borderBottomLeftRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
  },
}));

export const GameNewsCardHeader = styled(CardHeader)(() => ({
  backgroundColor: theme.palette.primary.main,
  height: 84,
}));

export const MiniTitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
  fontSize: 15,
}));

export const MiniDateTypography = styled(Typography)(({ theme }) => ({
  color: "#fafafa",
  fontSize: 13,
}));

export const GameNewsAvatar = styled(Avatar)(({ theme }) => ({
  pointerEvents: "none",
  width: 32,
  height: 32,
}));

export const GameNewsAvatarSkeleton = styled(Skeleton)(() => ({
  borderRadius: 1,
}));

export const GameNewsTitleSkeleton = styled(Skeleton)(() => ({
  marginBottom: 6,
}));

export const GameNewsSubHeaderSkeleton = styled(Skeleton)(() => ({
  marginBottom: 6,
}));

export const MiniCardContent = styled(CardContent)(({ theme }) => ({
  width: 400,
  color: "#fff",
}));

export const MiniContentTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    fontSize: 13,
  },
  whiteSpace: "pre-wrap",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",

  marginBottom: 10,
}));

export const GameNewsCardActions = styled(CardActions)(() => ({
  backgroundColor: theme.palette.primary.main,
}));

export const GlobalButtonSkeleton = styled(Skeleton)(() => ({
  width: 95,
  height: 35,
  borderRadius: 1,
}));

export const GameNewsButtonTypography = styled(Typography)(({ theme }) => ({
  color: "#fafafa",
  [theme.breakpoints.up("md")]: {
    fontSize: 15,
  },
}));

export const GameNewsCarousel = styled(Carousel)(() => ({
  height: 295,
  width: 1500,
}));

export const PopupPaperDesktop = styled(Paper)(({ theme }) => ({
  marginTop: 5,
  backgroundColor: theme.palette.tertiary.main,
  border: "3px solid #02d9fc",
  height: "auto",
  width: "auto",
  boxShadow:
    "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
  "::before": {
    content: "''",
    position: "absolute",
    top: 0,
    left: "calc(50% - 10px)", // Adjust this value to center the arrow
    borderLeft: "5px solid transparent",
    borderRight: "5px solid transparent",
    borderBottom: "5px solid #02d9fc",
  },
}));

export const PressListsSubheaderTypography = styled(Typography)(
  ({ theme }) => ({
    fontWeight: "600",
  }),
);

export const PressSubHeaderSkeleton = styled(Skeleton)(() => ({
  width: 100,
}));

export const PressPrimaryTextSkeleton = styled(Skeleton)(() => ({
  width: 300,
}));

export const PressSecondaryTextSkeleton = styled(Skeleton)(() => ({
  width: 50,
}));

export const PressCircleIcon = styled(CircleIcon)(() => ({
  fontSize: 12,
}));

export const ResourcesBox = styled(Box)(() => ({
  marginLeft: "35%",
}));

export const ResourcesListsSubheaderTypography = styled(Typography)(() => ({
  fontWeight: "600",
}));

export const ResourcesSubHeaderSkeleton = styled(Skeleton)(() => ({
  width: 100,
}));

export const ResourcesPrimaryTextSkeleton = styled(Skeleton)(() => ({
  width: 300,
}));

export const ResourcesCircleIcon = styled(CircleIcon)(() => ({
  fontSize: 12,
}));

export const VideoCardMedia = styled(CardMedia)(() => ({
  height: "100%",
  overflow: "hidden",
  position: "relative",
  transition: 300,
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
  transition: "transform .4s",
  "&:hover": {
    transform: "scale(1.05)",
    cursor: "pointer",
    filter: "brightness(115%)",
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 6px 12px, rgba(0, 0, 0, 0.23) 0px 6px 12px",
  },
}));

export const VideoCard = styled(Card)(() => ({
  height: 425,
  position: "relative",
  backgroundColor: "transparent",
}));

export const VideoGrid = styled(Grid)(() => ({
  height: "100%",
  position: "relative",
}));

export const VideoCarousel = styled(Carousel)(() => ({
  width: 850,
  borderRadius: theme.spacing(2),
}));

export const VideoBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 4,
  backgroundColor: "black",
}));

export const VideoBackdropIconButton = styled(IconButton)(({ theme }) => ({
  color: "#ffffff",
  margin: theme.spacing(2, 2),
  border: "3px solid #0084ff",
  backgroundColor: theme.palette.tertiary.main,
  "&:hover": {
    border: "3px solid #02d9fc",
    backgroundColor: "#005cb2",
  },
}));

export const VideoImageListItem = styled(ImageListItem)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: 586,
    height: 304,
  },
  [theme.breakpoints.up("md")]: {
    width: 879,
    height: 456,
  },
  [theme.breakpoints.up("lg")]: {
    width: 1172,
    height: 608,
  },
  [theme.breakpoints.up("xl")]: {
    width: 1465,
    height: 760,
  },
}));

export const VideoImageListItemBar = styled(ImageListItemBar)(() => ({
  background: "none",
}));
