import React, { Fragment, useEffect, useState } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { Scrollbar } from "components/scrollbar";
import ReactGA from "react-ga";
import { createTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import { trackingID, reportWebVitals } from "shared/constGlobal";
import Header from "./components/header";
import BackgroundParticles from "./components/backgroundParticles";
import Home from "./pages/home";
import Company from "./pages/company";
import Games from "./pages/games";
import News from "./pages/news";
import Error from "./pages/error";
import ContentManagementSystem from "./pages/contentManagementSystem";
import Footer from "./components/footer";
import CookieBanner from "./components/cookieBanner";

ReactGA.initialize(trackingID);

export default function App() {
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { initBodyOverlayScrollbars, setOverflow } = Scrollbar();
  const location = useLocation();

  useEffect(() => {
    ReactGA.set({ dimension1: "online" });
    ReactGA.ga("send", "pageview");
    reportWebVitals(({ id, name, value }) =>
      ReactGA.event({
        action: name,
        category: "Web Vitals",
        label: id,
        nonInteraction: true,
        value: Math.round(name === "CLS" ? value * 1000 : value),
      }),
    );
  }, [location]);

  useEffect(() => {
    initBodyOverlayScrollbars(document.body);
  }, [initBodyOverlayScrollbars]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  return (
    <Fragment>
      {location.pathname !== "/cms" && location.pathname !== "/cms/" && (
        <Fragment>
          <Header />
        </Fragment>
      )}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/games" element={<Games />} />
        <Route path="/news" element={<News />} />
        <Route path="/company" element={<Navigate to="/company/about" />} />
        <Route
          path="/company/*"
          element={<Company setOverflow={setOverflow} />}
        />
        <Route path="/cms" element={<ContentManagementSystem />} />
        <Route path="*" element={<Error />} />
      </Routes>

      {location.pathname !== "/cms" && location.pathname !== "/cms/" && (
        <Fragment>
          <Footer />
          <CookieBanner />
        </Fragment>
      )}
    </Fragment>
  );
}
