import React, { Suspense } from "react";
import { Fade } from "react-awesome-reveal";
import { createTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import {
  GlobalCardsBox,
  GameCardsMobile,
  GameCardsDesktop,
} from "shared/constGlobal";

export default function GameCards() {
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Fade cascade damping={0.1}>
      <GlobalCardsBox id="games">
        <Suspense fallback={null}>
          {isMobile ? <GameCardsMobile /> : <GameCardsDesktop />}
        </Suspense>
      </GlobalCardsBox>
    </Fade>
  );
}
