import React, { useState, useEffect, Fragment } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { createTheme } from "@mui/system";
import ReactGA from "react-ga";
import {
  Divider,
  useMediaQuery,
  Grid,
  Stack,
  Fade,
  Fab,
  Tooltip,
  Tabs,
  Tab,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import GamesIcon from "@mui/icons-material/Games";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import InfoIcon from "@mui/icons-material/Info";
import EmailIcon from "@mui/icons-material/Email";
import HeaderLogo from "images/headerLogo.webp";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ContactForm from "subcomponents/contactForm";
import MobileDrawer from "./mobileDrawer";
import {
  HeaderAppBar,
  HeaderToolbar,
  BorderLinearProgress,
  HeaderImage,
  HeaderButton,
  MobileMenuButtonFab,
  StyledSteamIconWhite,
  StyledXIconWhite,
  StyledFacebookIconWhite,
  StyledYoutubeIconWhite,
  StyledLinkedInIconWhite,
  HeaderLogoBox,
  HeaderSubLinkBar,
  HeaderTitleBox,
  HeaderTitleBoxTypography,
} from "shared/constGlobal";
import { styled } from "@mui/system";
import { tabsClasses, tabClasses } from "@mui/material/Tabs";
import {
  CompanyTab,
  CompanyTabList,
  companyTabPanels,
  CompanyBox,
} from "shared/constDesktop";
import { TabContext, TabPanel } from "@mui/lab";
import { fetchContents } from "shared/fetchContents";

export default function Header({ isDrawerOpened, handleToggleDrawer }) {
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xl"));
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(false);

  const [isOpenContact, setIsOpenContact] = useState(false);

  const [titleText, setTitleText] = useState("");

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleCloseDrawer = (isOpen) => {
    setDrawerOpen(isOpen);
  };

  const [content, setContent] = useState({
    content: {
      header: [
        {
          button: ["", "", ""],
          socialbuttontooltip: ["", "", "", "", ""],
        },
      ],
    },
  });

  const headerContentEN = content?.content?.header[0];

  const location = useLocation();

  const tabPaths = [
    { label: "About", path: "/company/about" },
    { label: "Articles", path: "/company/articles" },
    { label: "Press Kits", path: "/company/presskits" },
    { label: "Careers", path: "/company/careers" },
    { label: "Videos", path: "/company/videos" },
    { label: "Gallery", path: "/company/gallery" },
  ];

  const leftTabs = tabPaths.slice(0, Math.ceil(tabPaths.length / 2));
  const rightTabs = tabPaths.slice(Math.ceil(tabPaths.length / 2));

  const currentTab = tabPaths.find((tab) =>
    location.pathname.includes(tab.path),
  );

  const [value, setValue] = useState(currentTab ? currentTab.path : "about");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/company/${newValue}`);
  };

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setTitleText("SEACORP TECHNOLOGIES");
        break;
      case "/games":
        setTitleText("OUR GAMES");
        break;
      case "/news":
        setTitleText("LATEST NEWS");
        break;
      default:
        if (location.pathname.startsWith("/company/")) {
          setTitleText("COMPANY INFORMATION");
        } else if (location.pathname === "/error") {
          setTitleText("ERROR");
        } else {
          setTitleText("");
        }
        break;
    }
  }, [location.pathname]);

  const handlePageLoading = () => {
    setPageLoading(true);
  };

  const handleYoutube = () => {
    ReactGA.event({
      category: "Links",
      action: "Youtube",
      label: "Youtube Redirect",
      value: 1,
    });
  };

  const handleFacebook = () => {
    ReactGA.event({
      category: "Links",
      action: "Facebook",
      label: "Facebook Redirect",
      value: 1,
    });
  };

  const handleLinkedIn = () => {
    ReactGA.event({
      category: "Links",
      action: "LinkedIn",
      label: "LinkedIn Redirect",
      value: 1,
    });
  };

  const handleX = () => {
    ReactGA.event({
      category: "Links",
      action: "X",
      label: "X Redirect",
      value: 1,
    });
  };

  const handleSteam = () => {
    ReactGA.event({
      category: "Links",
      action: "Steam",
      label: "Steam Store Redirect",
      value: 1,
    });
  };

  const handleContactUs = () => {
    setIsOpenContact(!isOpenContact);
    setPageLoading(false);
  };

  const headerButtons = [
    {
      onClick: handlePageLoading,
      to: "/",
      icon: <HomeIcon />,
      title: "Home",
      id: 0,
    },
    {
      onClick: handlePageLoading,
      to: "/games",
      icon: <GamesIcon />,
      title: "Games",
      id: 1,
    },
    {
      onClick: handlePageLoading,
      to: "/news",
      icon: <NewspaperIcon />,
      title: "News",
      id: 2,
    },
    {
      onClick: handlePageLoading,
      to: "/company/about",
      icon: <InfoIcon />,
      title: "Company",
      id: 3,
    },
  ];

  const headerSocialButtons = [
    {
      icon: <StyledSteamIconWhite alt="Steam Logo" />,
      link: "https://store.steampowered.com/developer/Seacorp",
      ariaLabel: "Link to the SCT Steam page",
      tooltip: headerContentEN?.socialbuttontooltip[0],
      id: 0,
      onClick: handleSteam,
      handleCloseDrawer,
    },
    {
      icon: <StyledYoutubeIconWhite alt="Youtube Logo" />,
      link: "https://www.youtube.com/channel/UCh9_t29QZI3j0TMJAYOphxw",
      ariaLabel: "Link to the SCT Youtube channel",
      tooltip: headerContentEN?.socialbuttontooltip[1],
      id: 1,
      onClick: handleYoutube,
    },
    {
      icon: <StyledXIconWhite alt="X Logo" />,
      link: "https://x.com/seacorptech",
      ariaLabel: "Link to SCT on X",
      tooltip: headerContentEN?.socialbuttontooltip[2],
      id: 2,
      onClick: handleX,
    },
    {
      icon: <StyledFacebookIconWhite alt="Facebook Logo" />,
      link: "https://www.facebook.com/seacorpltd",
      ariaLabel: "Link to the SCT Facebook page",
      tooltip: headerContentEN?.socialbuttontooltip[3],
      id: 3,
      onClick: handleFacebook,
    },
    {
      icon: <StyledLinkedInIconWhite alt="LinkedIn Logo" />,
      link: "https://www.linkedin.com/company/seacorp-technologies/",
      ariaLabel: "Link to the SCT LinkedIn page",
      tooltip: headerContentEN?.socialbuttontooltip[4],
      id: 4,
      onClick: handleLinkedIn,
    },
    {
      icon: <EmailIcon />,
      ariaLabel: "Button to the Contact Us modal",
      tooltip: "Contact Us",
      id: 5,
      onClick: handleContactUs,
    },
  ];

  useEffect(() => {
    fetchContents(setContent);
    setPageLoading(false);
  }, [location]);

  useEffect(() => {
    const currentTab = tabPaths.find((tab) =>
      location.pathname.includes(tab.path),
    );
    if (currentTab) {
      setValue(currentTab.path);
    }
  }, [location.pathname]);

  const isCompanyRoute = location.pathname.startsWith("/company/");

  return (
    <Fragment>
      <HeaderAppBar position="sticky" elevation={24}>
        {pageLoading && <BorderLinearProgress color="secondary" />}
        <HeaderToolbar disableGutters={true}>
          {isMobile ? (
            <Grid container justifyContent="space-between" alignItems="center">
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              ></Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <MobileMenuButtonFab
                  aria-label="Toggle Mobile Drawer"
                  size="small"
                  onClick={handleDrawerToggle}
                >
                  {drawerOpen ? <CloseIcon /> : <MenuIcon />}
                </MobileMenuButtonFab>
              </Stack>
            </Grid>
          ) : (
            <Grid container justifyContent="space-between" alignItems="center">
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {headerButtons.map((src, index) => (
                  <HeaderButton
                    component={NavLink}
                    key={index}
                    to={src.to}
                    onClick={src.onClick}
                    sx={(theme) => ({
                      backgroundColor:
                        location.pathname === src.to ||
                        (src.to.startsWith("/company/") &&
                          location.pathname.startsWith("/company/"))
                          ? theme.palette.primary.dark
                          : null,
                    })}
                    variant="contained"
                    startIcon={src.icon}
                  >
                    <Divider
                      sx={{ mr: 1, backgroundColor: "#dedede" }}
                      orientation="vertical"
                      flexItem={true}
                    />
                    {src.title}
                  </HeaderButton>
                ))}
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                {headerSocialButtons.map((src, index) => (
                  <Tooltip
                    title={src.tooltip}
                    arrow
                    placement="bottom"
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -10],
                          },
                        },
                      ],
                    }}
                  >
                    <Fab
                      sx={{ marginRight: theme.spacing(2) }}
                      size="medium"
                      color="primary"
                      component="a"
                      href={src.link}
                      aria-label={src.ariaLabel}
                      target="_blank"
                      rel="noopener"
                      key={index}
                      onClick={src.onClick}
                    >
                      {src.icon}
                    </Fab>
                  </Tooltip>
                ))}
              </Stack>
            </Grid>
          )}
        </HeaderToolbar>
        {isMobile ? (
          <HeaderLogoBox>
            <Fade in={true} timeout={500}>
              <HeaderImage
                src={HeaderLogo}
                width="100%"
                height="100%"
                alt="logo"
              />
            </Fade>
          </HeaderLogoBox>
        ) : (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <HeaderLogoBox>
              <Fade in={true} timeout={500}>
                <HeaderImage
                  src={HeaderLogo}
                  width="100%"
                  height="100%"
                  alt="logo"
                />
              </Fade>
            </HeaderLogoBox>
            <HeaderTitleBox>
              <HeaderTitleBoxTypography
                align="center"
                variant="h1"
                component="div"
              >
                {titleText}
              </HeaderTitleBoxTypography>
            </HeaderTitleBox>
          </Stack>
        )}
      </HeaderAppBar>
      {isMobile ? null : (
        <HeaderSubLinkBar position="sticky" elevation={24}>
          <TabContext value={value}>
            <Grid
              container
              sx={(theme) => ({
                justifyContent: "space-around",
                alignItems: "center",
                height: "100%",
                [theme.breakpoints.down("xl")]: {
                  justifyContent: "space-between",
                },
              })}
            >
              <Grid item>
                <CompanyTabList
                  onChange={handleChange}
                  aria-label="company tabs"
                  TabIndicatorProps={{
                    style: {
                      transition: "none",
                      display: isCompanyRoute ? "block" : "none",
                    },
                  }}
                >
                  {leftTabs.map((tab) => (
                    <CompanyTab
                      label={tab.label}
                      value={tab.path}
                      key={tab.path}
                      component={NavLink}
                      to={tab.path}
                    />
                  ))}
                </CompanyTabList>
              </Grid>

              <Grid item>
                <CompanyTabList
                  onChange={handleChange}
                  aria-label="company tabs"
                  TabIndicatorProps={{
                    style: {
                      transition: "none",
                      display: isCompanyRoute ? "block" : "none",
                    },
                  }}
                >
                  {rightTabs.map((tab) => (
                    <CompanyTab
                      label={tab.label}
                      value={tab.path}
                      key={tab.path}
                      component={NavLink}
                      to={tab.path}
                    />
                  ))}
                </CompanyTabList>
              </Grid>
            </Grid>
          </TabContext>
        </HeaderSubLinkBar>
      )}
      {isMobile ? (
        <MobileDrawer
          loading={pageLoading}
          setPageLoading={setPageLoading}
          isDrawerOpened={drawerOpen}
          handleCloseDrawer={handleCloseDrawer}
        />
      ) : null}
      <ContactForm
        isContactOpened={isOpenContact}
        handleCloseContact={() => setIsOpenContact(false)}
      />
    </Fragment>
  );
}
