import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { PageContainer } from "shared/constGlobal";
import GameNews from "components/gameNews";

export default function Home() {
  return (
    <Fragment>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <link rel="canonical" href="https://www.seacorptech.com/news" />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="Seacorp Technologies, Video Games, Indie, Prospekt, Gunsmith, Kinetic Edge, Junkpunk, Blockville, News, Steam, PC Games"
        />
        <meta name="author" content="Seacorp Technologies" />
        <meta name="title" content="News | Seacorp Technologies" />
        <meta name="description" content="News from Seacorp Technologies." />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.seacorptech.com/news" />
        <meta property="og:title" content="News | Seacorp Technologies" />
        <meta
          property="og:description"
          content="News from Seacorp Technologies."
        />
        <meta property="og:image" content="/logo.webp" />

        <meta property="twitter:card" content="/logo.webp" />
        <meta
          property="twitter:url"
          content="https://www.seacorptech.com/news"
        />
        <meta property="twitter:title" content="News | Seacorp Technologies" />
        <meta
          property="twitter:description"
          content="News from Seacorp Technologies."
        />
        <title>News | Seacorp Technologies</title>
      </Helmet>
      <PageContainer maxWidth="xl">
        <GameNews />
      </PageContainer>
    </Fragment>
  );
}
