import React, { useState, useEffect, Fragment } from "react";
import { Stack } from "@mui/material";
import BackToTop from "components/backToTop";
import PrivacyPolicy from "subcomponents/privacyPolicy";
import TermsPolicy from "subcomponents/termsPolicy";
import CookiePolicy from "subcomponents/cookiePolicy";
import {
  FooterCgFileDocument,
  FooterTypography,
  FooterLink,
  FooterGrid,
  FooterBackToTopBox,
} from "shared/constGlobal";
import { fetchContents } from "shared/fetchContents";

export default function StickyFooter() {
  const [isOpenPrivacy, setIsOpenPrivacy] = useState(false);
  const [isOpenTerms, setIsOpenTerms] = useState(false);
  const [isOpenCookie, setIsOpenCookie] = useState(false);

  const [content, setContent] = useState({
    content: {
      gamecard: [
        {
          title: "",
        },
      ],
    },
  });

  const gameCardContentEN = content?.content?.footer?.[0];

  const handlePrivacyPolicy = () => {
    setIsOpenPrivacy(!isOpenPrivacy);
  };

  const handleTermsPolicy = () => {
    setIsOpenTerms(!isOpenTerms);
  };

  const handleCookiePolicy = () => {
    setIsOpenCookie(!isOpenCookie);
  };

  const footers = [
    {
      title: <FooterCgFileDocument />,
      description: [
        gameCardContentEN?.links[0],
        gameCardContentEN?.links[1],
        gameCardContentEN?.links[2],
      ],
      link: [],
      onClick: [
        () => handlePrivacyPolicy(),
        () => handleTermsPolicy(),
        () => handleCookiePolicy(),
      ],
    },
  ];

  const Copyright = () => {
    return (
      <FooterTypography variant="h1">
        {gameCardContentEN?.copyright}
        <FooterLink href="/" underline="hover">
          {gameCardContentEN?.company}
        </FooterLink>
        {new Date().getFullYear()}
        {"."}
      </FooterTypography>
    );
  };

  useEffect(() => {
    fetchContents(setContent);
  }, []);

  return (
    <Fragment>
      <FooterGrid>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <FooterBackToTopBox>
            <BackToTop />
          </FooterBackToTopBox>
        </Stack>
        {footers.map((src, index) => (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            key={index}
          >
            {src.title}

            {src.description.map((item, index) => (
              <FooterLink variant="h1" key={index} onClick={src.onClick[index]}>
                {item}
              </FooterLink>
            ))}
          </Stack>
        ))}
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          mt={8}
        >
          <Copyright />
        </Stack>
      </FooterGrid>
      <PrivacyPolicy
        isPrivacyOpened={isOpenPrivacy}
        handleClosePrivacy={() => setIsOpenPrivacy(false)}
      />
      <TermsPolicy
        isTermsOpened={isOpenTerms}
        handleCloseTerms={() => setIsOpenTerms(false)}
      />
      <CookiePolicy
        isCookieOpened={isOpenCookie}
        handleCloseCookie={() => setIsOpenCookie(false)}
      />
    </Fragment>
  );
}
