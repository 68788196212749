import React, { useState, Fragment } from "react";
import { Typography, DialogContent, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ContactForm from "./contactForm";
import { cookie } from "shared/constGlobal";
import {
  GlobalTitle,
  GlobalDialog,
  GlobalIconButton,
  GlobalOverlayScrollbarsComponent,
  GlobalTypography,
  GlobalLink,
} from "shared/constGlobal";
//import { fetchContents } from "shared/fetchContents";

export default function CookiePolicy({ isCookieOpened, handleCloseCookie }) {
  /*const [content, setContent] = useState({
    content: {
      cookiepolicy: [
        {
          title: "",
          text: "",
        },
      ],
    },
  });*/

  //const cookiePolicyContentEN = content?.content?.cookiepolicy[0];

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    handleCloseCookie(false);
  };
  const [isOpenContact, setIsOpenContact] = useState(false);
  const handleContactUs = () => {
    setIsOpenContact(!isOpenContact);
  };

  return (
    <Fragment>
      {cookie.map((src) => (
        <GlobalDialog open={isCookieOpened} onClose={handleClose} key={src.id}>
          <GlobalTitle variant="subtitle1" onClose={handleClose}>
            {src.title}
            <GlobalIconButton
              size="small"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </GlobalIconButton>
          </GlobalTitle>
          <GlobalOverlayScrollbarsComponent
            options={{
              scrollbars: {
                clickScrolling: true,
                theme: "os-theme-thick-blue",
              },
            }}
          >
            <DialogContent dividers>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Typography variant="body2" gutterBottom>
                  {src.textOne}

                  <GlobalLink href="https://www.seacorptech.com/">
                    {src.textLinkOne}
                  </GlobalLink>
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleOne}
                </GlobalTypography>
                <Typography variant="body2" gutterBottom>
                  {src.textTwo}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleTwo}
                </GlobalTypography>
                <Typography variant="body2" gutterBottom>
                  {src.textThree}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleThree}
                </GlobalTypography>
                <Typography variant="body2" gutterBottom>
                  {src.textFour}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleFour}
                </GlobalTypography>
                <Typography variant="subtitle3" component="div">
                  {src.subTitleFive}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textFive}
                </Typography>
                <Typography variant="subtitle3" component="div">
                  {src.subTitleSix}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textSix}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleSeven}
                </GlobalTypography>
                <Typography variant="body2" gutterBottom>
                  {src.textSeven}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textEight}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textNine}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textTen}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textEleven}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleEight}
                </GlobalTypography>
                <Typography variant="body2" gutterBottom>
                  {src.textTwelve}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textThirteen}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textFourteen}
                  <GlobalLink onClick={handleContactUs}>
                    {src.textLinkTwo}
                  </GlobalLink>
                  .
                </Typography>
              </Stack>
            </DialogContent>
          </GlobalOverlayScrollbarsComponent>
        </GlobalDialog>
      ))}
      <ContactForm
        isContactOpened={isOpenContact}
        handleCloseContact={() => setIsOpenContact(false)}
      />
    </Fragment>
  );
}
