import React, { useState, createRef, Fragment } from "react";
import axios from "axios";
import { Stack, Grid, Alert, AlertTitle } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import { ValidatorForm } from "react-material-ui-form-validator";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
import {
  HeaderImage,
  GlobalTitle,
  LoginDialog,
  LoginDialogContent,
  GlobalDialogActions,
  GlobalButton,
  GlobalTextValidator,
} from "shared/constGlobal";
import HeaderLogo from "images/headerLogo.webp";

export default function Login({ onLogIn }) {
  const mongooseAPIURL = process.env.REACT_APP_MONGOOSE_API_URL;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const usernameRef = createRef();
  const passwordRef = createRef();

  const handleUserNameBlur = (event) => {
    usernameRef.current.validate(event.target.value);
  };

  const handlePasswordBlur = (event) => {
    passwordRef.current.validate(event.target.value);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send login request to server using Axios
      await axios.post(mongooseAPIURL, {
        username,
        password,
      });
      // Handle successful login
      // Reset form fields and error state
      setUsername("");
      setPassword("");
      setError(false);
      onLogIn();
    } catch (error) {
      // Handle login error
      setError(true);
    }
  };

  return (
    <Fragment>
      <LoginDialog open={true}>
        <GlobalTitle variant="subtitle1">
          <Stack direction="row" alignItems="center" spacing={2}>
            <HeaderImage
              src={HeaderLogo}
              width="100%"
              height="100%"
              alt="logo"
            />
            {"Website Content Management System"}
          </Stack>
        </GlobalTitle>

        <ValidatorForm onSubmit={handleSubmit} instantValidate={false}>
          <LoginDialogContent dividers>
            <Stack direction="column" justifyContent="center" spacing={2}>
              <GlobalTextValidator
                fullWidth
                ref={usernameRef}
                variant="filled"
                placeholder="Username"
                name="username"
                value={username}
                onChange={handleUsernameChange}
                onBlur={handleUserNameBlur}
                validators={["required"]}
                errorMessages={["This field is required."]}
                style={{ height: "64px" }} // Set a fixed height for the input box
              />

              <GlobalTextValidator
                fullWidth
                ref={passwordRef}
                variant="filled"
                placeholder="Password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                onBlur={handlePasswordBlur}
                validators={["required"]}
                errorMessages={["This field is required."]}
                autoComplete="current-password"
                id="filled-adornment-password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password UploadIcon"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                style={{ height: "64px" }} // Set a fixed height for the input box
              />
            </Stack>
          </LoginDialogContent>
          <GlobalDialogActions>
            <Grid container spacing={3}>
              {error && (
                <Grid item xs={12}>
                  <Alert
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setError(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    style={{
                      backgroundColor: "#160b0b",
                      color: "#f4c7c3",
                      width: 550,
                      height: 76,
                      boxShadow: "none",
                    }}
                    severity="error"
                  >
                    <AlertTitle>Error</AlertTitle>
                    Invalid username or password, please contact the system
                    administrator.
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <GlobalButton
                  endIcon={<LoginIcon />}
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Sign In
                </GlobalButton>
              </Grid>
            </Grid>
          </GlobalDialogActions>
        </ValidatorForm>
      </LoginDialog>
    </Fragment>
  );
}
