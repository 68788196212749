import React, { Fragment /*useState*/ } from "react";
import { Typography, DialogContent, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { privacy } from "shared/constGlobal";
import {
  GlobalTitle,
  GlobalDialog,
  GlobalIconButton,
  GlobalOverlayScrollbarsComponent,
  GlobalTypography,
  GlobalLink,
} from "shared/constGlobal";
//import { fetchContents } from "shared/fetchContents";

export default function PrivacyPolicy({ isPrivacyOpened, handleClosePrivacy }) {
  /*const [content, setContent] = useState({
    content: {
      privacypolicy: [
        {
          title: "",
          text: "",
        },
      ],
    },
  });*/

  //const privacyPolicyContentEN = content?.content?.privacypolicy[0];

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    handleClosePrivacy(false);
  };

  return (
    <Fragment>
      {privacy.map((src) => (
        <GlobalDialog open={isPrivacyOpened} onClose={handleClose} key={src.id}>
          <GlobalTitle variant="subtitle1" onClose={handleClose}>
            {src.title}
            <GlobalIconButton
              size="small"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </GlobalIconButton>
          </GlobalTitle>{" "}
          <GlobalOverlayScrollbarsComponent
            options={{
              scrollbars: {
                clickScrolling: true,
                theme: "os-theme-thick-blue",
              },
            }}
          >
            <DialogContent dividers>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                <Typography variant="body2" gutterBottom>
                  {src.textOne}
                  <GlobalLink href="/">{src.textLinkOne}</GlobalLink>
                  {src.textTwo}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textThree}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textFour}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleOne}
                </GlobalTypography>
                <Typography variant="body2" gutterBottom>
                  {src.textFive}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleTwo}
                </GlobalTypography>
                <Typography variant="body2" gutterBottom>
                  {src.textSix}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textSeven}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textEight}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleThree}
                </GlobalTypography>
                <Typography variant="subtitle3" gutterBottom>
                  {src.subTitleFour}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textNine}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textTen}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textEleven}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textTwelve}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textThirteen}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textFourteen}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textFifteen}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleFive}
                </GlobalTypography>
                <Typography variant="body2" gutterBottom>
                  {src.textSixteen}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleSix}
                </GlobalTypography>
                <Typography variant="body2" gutterBottom>
                  {src.textSeventeen}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleSeven}
                </GlobalTypography>
                <Typography variant="body2" gutterBottom>
                  {src.textSeventeen}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textEighteen}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textNineteen}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textTwenty}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleEight}
                </GlobalTypography>
                <Typography variant="body2" gutterBottom>
                  {src.textTwentyOne}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textTwentyTwo}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleNine}
                </GlobalTypography>
                <Typography variant="subtitle3" gutterBottom>
                  {src.subTitleTen}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textTwentyThree}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textTwentyFour}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textTwentyFive}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textTwentySix}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleEleven}
                </GlobalTypography>
                <Typography variant="subtitle3" gutterBottom>
                  {src.subTitleTwelve}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textTwentySeven}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textTwentyEight}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textTwentyNine}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textThirty}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textThirtyOne}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textThirtyTwo}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textThirtyThree}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleThirteen}
                </GlobalTypography>
                <Typography variant="body2" gutterBottom>
                  {src.textThirtyFour}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textThirtyFive}
                </Typography>
              </Stack>
            </DialogContent>
          </GlobalOverlayScrollbarsComponent>
        </GlobalDialog>
      ))}
    </Fragment>
  );
}
