import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { PageContainer } from "shared/constGlobal";
import HeroBanner from "components/heroBanner";

export default function Home() {
  return (
    <Fragment>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="canonical" href="https://www.seacorptech.com/" />
        <meta name="robots" content="index, follow" />

        <meta
          name="keywords"
          content="Seacorp Technologies, Video Games, Indie, Prospekt, Gunsmith, Kinetic Edge, Junkpunk, Blockville, Home, Steam, PC Games"
        />
        <meta name="author" content="Seacorp Technologies" />
        <meta name="title" content="Home | Seacorp Technologies" />
        <meta
          name="description"
          content="Seacorp Technologies aims to make great video games. We're an enthusiastic team of talented developers based in the south coast of England."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.seacorptech.com/" />
        <meta property="og:title" content="Home | Seacorp Technologies" />
        <meta
          property="og:description"
          content="Seacorp Technologies aims to make great video games. We're an enthusiastic team of talented developers based in the south coast of England."
        />
        <meta property="og:image" content="/logo.webp" />

        <meta property="twitter:card" content="/logo.webp" />
        <meta property="twitter:url" content="https://www.seacorptech.com/" />
        <meta property="twitter:title" content="Home | Seacorp Technologies" />
        <meta
          property="twitter:description"
          content="Seacorp Technologies aims to make great video games. We're an enthusiastic team of talented developers based in the south coast of England."
        />
        <title>Home | Seacorp Technologies</title>
      </Helmet>
      <PageContainer maxWidth="xl">
        <HeroBanner />
      </PageContainer>
    </Fragment>
  );
}
