import React, { Suspense } from "react";
import { HeroBannerVideo, HeroBannerContent } from "shared/constGlobal";

export default function HeroBanner() {
  return (
    <Suspense fallback={null}>
      <HeroBannerVideo />
      <HeroBannerContent />
    </Suspense>
  );
}
