import React, { useState, Fragment } from "react";
import { useCookies } from "react-cookie";
import { Collapse, AlertTitle, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CookiePolicy from "subcomponents/cookiePolicy";
import {
  CookieBannerAlert,
  CookieBiCookie,
  GlobalIconButton,
  cookieBanner,
  GlobalLink,
} from "shared/constGlobal";

export default function CookieBanner() {
  const [open, setOpen] = useState(true);
  const [cookies, setCookie] = useCookies();
  const [isOpenCookie, setIsOpenCookie] = useState(false);
  const handleCookiePolicy = () => {
    setIsOpenCookie(!isOpenCookie);
  };
  const handleCookie = () => {
    setOpen(false);
    setCookie("cookieConsent", true, { path: "/", maxAge: 15778800 });
  };
  if (cookies.cookieConsent) {
    return null;
  }
  return (
    <Fragment>
      <CookieBannerAlert
        icon={<CookieBiCookie sx={{ mt: 0.5 }} />}
        severity="info"
        action={
          <GlobalIconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleCookie}
          >
            <CloseIcon fontSize="inherit" />
          </GlobalIconButton>
        }
      >
        {cookieBanner.map((src) => (
          <Collapse in={open} key={src.id}>
            <AlertTitle variant="h6">{src.cookieTitle}</AlertTitle>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              {src.cookieText}
              <GlobalLink onClick={handleCookiePolicy}>
                {src.cookieLinkText}
              </GlobalLink>
            </Stack>
          </Collapse>
        ))}
      </CookieBannerAlert>
      <CookiePolicy
        isCookieOpened={isOpenCookie}
        handleCloseCookie={() => setIsOpenCookie(false)}
      />
    </Fragment>
  );
}
