import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
//import { useOverlayScrollbars } from "overlayscrollbars-react";
import { ScrollButton, ScrollFab } from "shared/constGlobal";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function ScrollTop(props) {
  const { children } = props;
  const ref = useRef(null);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <ScrollButton onClick={handleClick} role="presentation">
      {children}
    </ScrollButton>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
};

export default function BackToTop(props) {
  return (
    <ScrollTop {...props}>
      <ScrollFab size="small" aria-label="Back to top">
        <KeyboardArrowUpIcon />
      </ScrollFab>
    </ScrollTop>
  );
}
