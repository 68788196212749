import { cacheResponse } from "./cacheFetchResponse";

export const fetchContents = (setContent, setContentLoading) => {
  let fetchContents = [process.env.REACT_APP_TEXT_EN];

  cacheResponse
    .all(fetchContents.map((contents) => cacheResponse.get(contents)))
    .then(
      cacheResponse.spread((...response) => {
        setContent({
          content: {
            header: response[0].data.content.header,
            herobanner: response[0].data.content.herobanner,
            gamecard: response[0].data.content.gamecard,
            gamenewscard: response[0].data.content.gamenewscard,
            companyinfocard: response[0].data.content.companyinfocard,
            contactform: response[0].data.content.contactform,
            privacypolicy: response[0].data.content.privacypolicy,
            termspolicy: response[0].data.content.termspolicy,
            cookiepolicy: response[0].data.content.cookiepolicy,
            footer: response[0].data.content.footer,
          },
        });
        setContentLoading(false);
      }),
    )
    .catch((error) => console.error("Error fetching site contents:", error));
};
