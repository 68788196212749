import React, { useState, useEffect, createRef, Fragment } from "react";
import axios from "axios";
import { Stack, Grid, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { ValidatorForm } from "react-material-ui-form-validator";
import ReCaptchaV2 from "react-google-recaptcha";
import {
  GlobalTitle,
  GlobalSubTitle,
  GlobalDialog,
  GlobalIconButton,
  GlobalOverlayScrollbarsComponent,
  GlobalTextValidator,
  GlobalDialogActions,
  GlobalButton,
} from "shared/constGlobal";
import { fetchContents } from "shared/fetchContents";

export default function ContactForm({ isContactOpened, handleCloseContact }) {
  const nodemailerAPIURL = process.env.REACT_APP_NODEMAILER_API_URL;
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const [contentLoading, setContentLoading] = useState(true);

  const [content, setContent] = useState({
    content: {
      contactform: [
        {
          title: "",
          name: "",
          email: "",
          subject: "",
          message: "",
        },
      ],
    },
  });

  const contactFormContentEN = content?.content?.contactform[0];

  const [response, setResponse] = useState();
  const [data, setData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    sent: false,
    buttonText: "Submit",
    err: "",
  });
  const [verified, setVerified] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const resetForm = () => {
    setData({
      name: "",
      email: "",
      subject: "",
      message: "",
      sent: false,
      buttonText: "Submit",
      err: "",
    });
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    handleCloseContact(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setButtonLoading(true);
    setData({
      ...data,
      buttonText: "Submitting...",
    });

    await axios
      .post(nodemailerAPIURL, {
        data,
        response,
      })
      .then((res) => {
        setTimeout(() => {
          setButtonLoading(false);
          setData({
            ...data,
            sent: true,
            buttonText: "Submitted",
            err: "success",
          });
          setTimeout(() => {
            resetForm();
          }, 1000);
        }, 1000);
      })
      .catch((err) => {
        setButtonLoading(false);
        setData({
          ...data,
          buttonText: "Failed to submit",
          err: "fail",
        });
        setTimeout(() => {
          resetForm();
        }, 1000);
      });
  };

  const fullnameRef = createRef();
  const emailRef = createRef();
  const subjectRef = createRef();
  const messageRef = createRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleFullNameBlur = (event) => {
    fullnameRef.current.validate(event.target.value);
  };

  const handleEmailBlur = (event) => {
    emailRef.current.validate(event.target.value);
  };

  const handleSubjectBlur = (event) => {
    subjectRef.current.validate(event.target.value);
  };

  const handleMessageBlur = (event) => {
    messageRef.current.validate(event.target.value);
  };

  const onRecaptchaChange = (e) => {
    setVerified(true);
    setResponse(e);
  };

  const onRecaptchaExpired = () => {
    setVerified(false);
  };

  useEffect(() => {
    fetchContents(setContent, setContentLoading);
  }, []);

  return (
    <Fragment>
      {contentLoading ? (
        <GlobalDialog open={isContactOpened} onClose={handleClose}>
          <GlobalTitle variant="subtitle1" onClose={handleClose}>
            {contactFormContentEN?.title}
            <GlobalIconButton
              size="small"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </GlobalIconButton>
          </GlobalTitle>
          <GlobalSubTitle variant="caption">
            For business enquiries only
          </GlobalSubTitle>

          <ValidatorForm onSubmit={handleSubmit} instantValidate={false}>
            <DialogContent dividers>
              <Stack direction="column" justifyContent="center" spacing={2}>
                <GlobalTextValidator
                  fullWidth
                  ref={fullnameRef}
                  variant="filled"
                  placeholder={contactFormContentEN?.name}
                  name="name"
                  value={data.name}
                  onChange={handleChange}
                  onBlur={handleFullNameBlur}
                  validators={["required", "minStringLength:2"]}
                  errorMessages={[
                    "This field is required.",
                    "Full name is too short.",
                  ]}
                />

                <GlobalTextValidator
                  fullWidth
                  ref={emailRef}
                  variant="filled"
                  placeholder={contactFormContentEN?.email}
                  onBlur={handleEmailBlur}
                  onChange={handleChange}
                  name="email"
                  value={data.email}
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "This field is required.",
                    "Email is not valid.",
                  ]}
                />

                <GlobalTextValidator
                  fullWidth
                  variant="filled"
                  ref={subjectRef}
                  placeholder={contactFormContentEN?.subject}
                  name="subject"
                  value={data.subject}
                  onChange={handleChange}
                  onBlur={handleSubjectBlur}
                  validators={["required", "minStringLength:2"]}
                  errorMessages={[
                    "This field is required.",
                    "Subject matter too short.",
                  ]}
                />
                <GlobalTextValidator
                  fullWidth
                  ref={messageRef}
                  maxRows={8}
                  minRows={8}
                  multiline
                  variant="filled"
                  placeholder={contactFormContentEN?.message}
                  name="message"
                  value={data.message}
                  onChange={handleChange}
                  onBlur={handleMessageBlur}
                  validators={["required", "minStringLength:2"]}
                  errorMessages={[
                    "This field is required.",
                    "Message is too short.",
                  ]}
                />
              </Stack>
            </DialogContent>

            <GlobalDialogActions>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <ReCaptchaV2
                  onChange={onRecaptchaChange}
                  onExpired={onRecaptchaExpired}
                  sitekey={recaptchaSiteKey}
                />
                <GlobalButton
                  disabled={!verified}
                  endIcon={<SendIcon />}
                  loading={buttonLoading}
                  loadingPosition="end"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {data.buttonText}
                </GlobalButton>
              </Grid>
            </GlobalDialogActions>
          </ValidatorForm>
        </GlobalDialog>
      ) : (
        <GlobalDialog open={isContactOpened} onClose={handleClose}>
          <GlobalTitle variant="subtitle1" onClose={handleClose}>
            {contactFormContentEN?.title}
            <GlobalIconButton
              size="small"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </GlobalIconButton>
          </GlobalTitle>
          <GlobalSubTitle variant="caption">
            For business enquiries only
          </GlobalSubTitle>

          <ValidatorForm onSubmit={handleSubmit} instantValidate={false}>
            <DialogContent dividers>
              <Stack direction="column" justifyContent="center" spacing={2}>
                <GlobalTextValidator
                  fullWidth
                  ref={fullnameRef}
                  variant="filled"
                  placeholder={contactFormContentEN?.name}
                  name="name"
                  value={data.name}
                  onChange={handleChange}
                  onBlur={handleFullNameBlur}
                  validators={["required", "minStringLength:2"]}
                  errorMessages={[
                    "This field is required.",
                    "Full name is too short.",
                  ]}
                />

                <GlobalTextValidator
                  fullWidth
                  ref={emailRef}
                  variant="filled"
                  placeholder={contactFormContentEN?.email}
                  onBlur={handleEmailBlur}
                  onChange={handleChange}
                  name="email"
                  value={data.email}
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "This field is required.",
                    "Email is not valid.",
                  ]}
                />

                <GlobalTextValidator
                  fullWidth
                  variant="filled"
                  ref={subjectRef}
                  placeholder={contactFormContentEN?.subject}
                  name="subject"
                  value={data.subject}
                  onChange={handleChange}
                  onBlur={handleSubjectBlur}
                  validators={["required", "minStringLength:2"]}
                  errorMessages={[
                    "This field is required.",
                    "Subject matter too short.",
                  ]}
                />

                <GlobalTextValidator
                  fullWidth
                  ref={messageRef}
                  maxRows={8}
                  minRows={8}
                  multiline
                  variant="filled"
                  placeholder={contactFormContentEN?.message}
                  name="message"
                  value={data.message}
                  onChange={handleChange}
                  onBlur={handleMessageBlur}
                  validators={["required", "minStringLength:2"]}
                  errorMessages={[
                    "This field is required.",
                    "Message is too short.",
                  ]}
                />
              </Stack>
            </DialogContent>

            <GlobalDialogActions>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <ReCaptchaV2
                  onChange={onRecaptchaChange}
                  onExpired={onRecaptchaExpired}
                  sitekey={recaptchaSiteKey}
                />
                <GlobalButton
                  disabled={!verified}
                  endIcon={<SendIcon />}
                  loading={buttonLoading}
                  loadingPosition="end"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {data.buttonText}
                </GlobalButton>
              </Grid>
            </GlobalDialogActions>
          </ValidatorForm>
        </GlobalDialog>
      )}
    </Fragment>
  );
}
