import React, { Fragment, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import ReactGA from "react-ga";
import {
  Divider,
  Stack,
  SvgIcon,
  Link,
  SpeedDialAction,
  ListItemText,
  Typography,
  Collapse,
  Box,
  Button,
  Fab,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import HomeIcon from "@mui/icons-material/Home";
import GamesIcon from "@mui/icons-material/Games";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import EmailIcon from "@mui/icons-material/Email";
import InfoIcon from "@mui/icons-material/Info";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ContactForm from "subcomponents/contactForm";
import {
  HeaderAppBar,
  MobileDrawerPaper,
  MobileDrawerListItemButton,
  MobileDrawerListItemIcon,
  MobileDrawerStack,
  MobileDrawerSpeedDial,
  StyledSteamIconWhite,
  StyledYoutubeIconWhite,
  StyledLinkedInIconWhite,
  StyledXIconWhite,
  StyledFacebookIconWhite,
} from "shared/constGlobal";
import { HeaderToolbar } from "shared/constGlobal";

export default function MobileDrawer({
  isDrawerOpened,
  handleCloseDrawer,
  setPageLoading,
}) {
  let navigate = useNavigate();

  const [isOpenContact, setisOpenContact] = useState(false);
  const [isCompanyOpen, setIsCompanyOpen] = useState(false);

  const handlePageLoading = () => {
    setPageLoading(true);
  };
  const handleContactUs = () => {
    setisOpenContact(!isOpenContact);
  };
  const handleYoutube = () => {
    ReactGA.event({
      category: "Links",
      action: "Youtube",
      label: "Youtube Server Redirect",
      value: 1,
    });
  };

  const handleFacebook = () => {
    ReactGA.event({
      category: "Links",
      action: "Facebook",
      label: "Facebook Server Redirect",
      value: 1,
    });
  };
  const handleLinkedIn = () => {
    ReactGA.event({
      category: "Links",
      action: "LinkedIn",
      label: "LinkedIn Redirect",
      value: 1,
    });
  };
  const handleX = () => {
    ReactGA.event({
      category: "Links",
      action: "X",
      label: "X Server Redirect",
      value: 1,
    });
  };
  const handleSteam = () => {
    ReactGA.event({
      category: "Links",
      action: "Steam",
      label: "Steam Store Redirect",
      value: 1,
    });
  };
  const drawerList = [
    {
      to: "/",
      icon: <HomeIcon />,
      title: "HOME",
      id: 0,

      onClick: handlePageLoading,
    },
    {
      to: "/games",
      icon: <GamesIcon />,
      title: "GAMES",
      id: 1,

      onClick: handlePageLoading,
    },
    {
      to: "/news",
      icon: <NewspaperIcon />,
      title: "NEWS",
      id: 2,

      onClick: handlePageLoading,
    },
  ];

  const companySubLinks = [
    { to: "/company/about", title: "About" },
    { to: "/company/articles", title: "Articles" },
    { to: "/company/presskits", title: "Press Kits" },
    { to: "/company/videos", title: "Videos" },
    { to: "/company/gallery", title: "Gallery" },
  ];
  const headerSocialButtons = [
    {
      icon: <StyledSteamIconWhite alt="Steam Logo" />,
      link: "https://store.steampowered.com/developer/Seacorp",
      ariaLabel: "Link to the SCT Steam page",
      id: 0,
      onClick: handleSteam,
      handleCloseDrawer,
    },
    {
      icon: <StyledYoutubeIconWhite alt="Youtube Logo" />,
      link: "https://www.youtube.com/channel/UCh9_t29QZI3j0TMJAYOphxw",
      ariaLabel: "Link to the SCT Youtube channel",
      id: 1,
      onClick: handleYoutube,
    },
    {
      icon: <StyledXIconWhite alt="X Logo" />,
      link: "https://x.com/seacorptech",
      ariaLabel: "Link to SCT on X",
      id: 2,
      onClick: handleX,
    },
    {
      icon: <StyledFacebookIconWhite alt="Facebook Logo" />,
      link: "https://www.facebook.com/seacorpltd",
      ariaLabel: "Link to the SCT Facebook page",
      id: 3,
      onClick: handleFacebook,
    },
    {
      icon: <StyledLinkedInIconWhite alt="LinkedIn Logo" />,
      link: "https://www.linkedin.com/company/seacorp-technologies/",
      ariaLabel: "Link to the SCT LinkedIn page",
      id: 4,
      onClick: handleLinkedIn,
    },
    {
      icon: <EmailIcon />,
      ariaLabel: "Button to the Contact Us modal",
      id: 5,
      onClick: handleContactUs,
    },
  ];

  const handleCompanyClick = () => {
    setIsCompanyOpen(!isCompanyOpen);
  };

  return (
    <Fragment>
      <MobileDrawerPaper
        open={isDrawerOpened}
        onClose={() => handleCloseDrawer(false)}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="left"
          sx={{ marginTop: 16 }}
        >
          {drawerList.map((src, index) => (
            <MobileDrawerListItemButton
              component={NavLink}
              key={index}
              to={src.to}
              onClick={src.onClick}
              sx={(theme) => ({
                "&.active": {
                  backgroundColor: theme.palette.primary.dark,
                  borderTop: `3px solid ${theme.palette.tertiary.main}`,
                  borderBottom: `3px solid ${theme.palette.tertiary.main}`,
                },
              })}
            >
              <MobileDrawerListItemIcon>{src.icon}</MobileDrawerListItemIcon>
              <Divider
                sx={{ mr: 1, backgroundColor: "#dedede" }}
                orientation="vertical"
                flexItem={true}
              />
              <ListItemText
                primary={
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {src.title}
                  </Typography>
                }
              />
            </MobileDrawerListItemButton>
          ))}
          <MobileDrawerListItemButton onClick={handleCompanyClick}>
            <MobileDrawerListItemIcon>
              <InfoIcon />
            </MobileDrawerListItemIcon>
            <Divider
              sx={{ mr: 1, backgroundColor: "#dedede" }}
              orientation="vertical"
              flexItem={true}
            />
            <ListItemText
              primary={
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  COMPANY
                </Typography>
              }
            />
            {isCompanyOpen ? <ExpandLess /> : <ExpandMore />}
          </MobileDrawerListItemButton>

          <Collapse in={isCompanyOpen} timeout="auto" unmountOnExit>
            {companySubLinks.map((subLink, index) => (
              <MobileDrawerListItemButton
                component={NavLink}
                key={index}
                to={subLink.to}
                onClick={handlePageLoading}
                sx={(theme) => ({
                  "&.active": {
                    backgroundColor: theme.palette.primary.dark,
                    borderLeft: `3px solid ${theme.palette.tertiary.main}`,
                  },
                })}
              >
                <ListItemText
                  primary={
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {subLink.title}
                    </Typography>
                  }
                />
              </MobileDrawerListItemButton>
            ))}
          </Collapse>
        </Stack>
        <Divider />

        <Box
          sx={(theme) => ({
            p: 2,
            position: "absolute",
            width: "100%",
            bottom: 0,
            backgroundColor: theme.palette.primary.dark,
          })}
        >
          <Stack
            direction="row"
            spacing={1}
            useFlexGap
            sx={{ flexWrap: "wrap", justifyContent: "center" }}
          >
            {headerSocialButtons.map((src, index) => (
              <Fab
                size="medium"
                color="primary"
                component="a"
                href={src.link}
                aria-label={src.ariaLabel}
                target="_blank"
                rel="noopener"
                key={index}
                onClick={src.onClick}
              >
                {src.icon}
              </Fab>
            ))}
          </Stack>
        </Box>
      </MobileDrawerPaper>
      <ContactForm
        isContactOpened={isOpenContact}
        handleCloseContact={() => setisOpenContact(false)}
      />
    </Fragment>
  );
}
