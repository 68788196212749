import { createTheme } from "@mui/material/styles";
import LetterGothic from "fonts/letterGothic.woff2";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#2f4e6b",
    },
    secondary: {
      main: "#02d9fc",
    },
    tertiary: {
      main: "#0084ff",
    },
    text: {
      primary: "#fff",
      secondary: "#02d9fc",
    },
    background: {
      default: "#263238",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: "Letter Gothic";
        font-style: normal;
        font-weight: normal;
        src: local("Letter Gothic"),
          url(${LetterGothic}) format("woff2");
        font-display: swap;
      },
      .os-theme-thick-blue {
        box-sizing: border-box;
        --os-size: 5px;
        --os-padding-perpendicular: 0px;
        --os-padding-axis: 0px;
        --os-track-border-radius: 0px;
        --os-handle-interactive-area-offset: 4px;
        --os-handle-border-radius: 0px;
      }

      .os-theme-thick-blue {
        --os-handle-bg: rgb(0,132,255);
        --os-handle-bg-hover: rgb(0,132,255);
        --os-handle-bg-active: rgb(0,132,255);
        background: rgb(222,222,222);
      }

      .os-no-css-vars.os-theme-thick-blue.os-scrollbar .os-scrollbar-handle {
        border-radius: 10px;
      }
      .os-no-css-vars.os-theme-thick-blue.os-scrollbar .os-scrollbar-track {
        border-radius: 10px;
      }
      .os-no-css-vars.os-theme-thick-blue.os-scrollbar .os-scrollbar-handle {
        border-radius: 10px;
      }
      .os-no-css-vars.os-theme-thick-blue.os-scrollbar-horizontal {
        padding: 2px 2px;
        right: 10px;
        height: 10px;
      }
      .os-no-css-vars.os-theme-thick-blue.os-scrollbar-horizontal.os-scrollbar-cornerless {
        right: 0;
      }
      .os-no-css-vars.os-theme-thick-blue.os-scrollbar-horizontal.os-scrollbar-rtl {
        left: 10px;
        right: 0;
      }
      .os-no-css-vars.os-theme-thick-blue.os-scrollbar-horizontal.os-scrollbar-rtl.os-scrollbar-cornerless {
        left: 0;
      }
      .os-no-css-vars.os-theme-thick-blue.os-scrollbar-horizontal .os-scrollbar-handle {
        min-width: 33px;
        max-width: none;
      }
      .os-no-css-vars.os-theme-thick-blue.os-scrollbar-horizontal {
        top: calc((2px + 4px) * -1);
        bottom: calc(2px * -1);
      }
      .os-no-css-vars.os-theme-thick-blue.os-scrollbar-vertical {
        padding: 2px 2px;
        bottom: 10px;
        width: 10px;
      }
      .os-no-css-vars.os-theme-thick-blue.os-scrollbar-vertical.os-scrollbar-cornerless {
        bottom: 0;
      }
      .os-no-css-vars.os-theme-thick-blue.os-scrollbar-vertical .os-scrollbar-handle {
        min-height: 33px;
        max-height: none;
      }
      .os-no-css-vars.os-theme-thick-blue.os-scrollbar-vertical .os-scrollbar-handle:before {
        left: calc((2px + 4px) * -1);
        right: calc(2px * -1);
      }
      .os-no-css-vars.os-theme-thick-blue.os-scrollbar-vertical.os-scrollbar-rtl .os-scrollbar-handle:before {
        right: calc((2px + 4px) * -1);
        left: calc(2px * -1);
      }
      .os-no-css-vars.os-theme-thick-blue .os-scrollbar-handle {
        background: rgba(0, 0, 0, 0.44);
      }
      .os-no-css-vars.os-theme-thick-blue:hover .os-scrollbar-handle {
        background: rgba(0, 0, 0, 0.55);
      }
      .os-no-css-vars.os-theme-thick-blue:active .os-scrollbar-handle {
        background: rgba(0, 0, 0, 0.66);
      }
      `,
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: '"Letter Gothic", Arial, Helvetica, sans-serif',
          fontDisplay: "swap",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"Letter Gothic", Arial, Helvetica, sans-serif',
          fontDisplay: "swap",
          color: "#fff",
          border: "3px solid #0084ff",
          backgroundColor: "#0084ff",
          "&:hover": {
            border: "3px solid #02d9fc",
            backgroundColor: "#005cb2",
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          color: "#fff",
          border: "3px solid #0084ff",
          backgroundColor: "#0084ff",
          "&:hover": {
            border: "3px solid #02d9fc",
            backgroundColor: "#005cb2",
          },
        },
      },
    },
    MuiSpeedDialAction: {
      styleOverrides: {
        fab: {
          color: "#fff",
          border: "3px solid #0084ff",
          backgroundColor: "#0084ff",
          "&:hover": {
            border: "3px solid #02d9fc",
            backgroundColor: "#005cb2",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: '"Letter Gothic", Arial, Helvetica, sans-serif',
          fontDisplay: "swap",
          color: "#fff",
          backgroundColor: "#0084ff",
          border: "2px solid #02d9fc",
          fontWeight: "bold",
        },
        arrow: {
          color: "#02d9fc",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          "&:before": {
            borderBottom: "1px solid #02d9fc",
          },
          "&:after": {
            borderBottom: "2px solid #02d9fc",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#02d9fc",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: 9,
          "&.Mui-selected": {
            color: "#02d9fc",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#02d9fc",
          textDecoration: "none",
          cursor: "pointer",
          "&:hover": {
            color: "#0197b0",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        secondary: {
          color: "#fff",
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          color: "#fff",
          backgroundColor: "transparent",
          fontWeight: 500,
          fontSize: "1.25rem",
          lineHeight: 1.6,
          letterSpacing: "0.0075em",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthXl: {
          maxWidth: "3000px !important",
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        root: { color: "#2f4e6b" },
      },
    },
  },
  zIndex: {
    alert: 1600,
  },
  typography: {
    fontFamily: ["Letter Gothic", "Montserrat"].join(","),
    fontDisplay: "swap",
    h1: {
      fontFamily: '"Letter Gothic", Arial, Helvetica, sans-serif',
      fontDisplay: "swap",
    },
    h2: {
      fontFamily: '"Letter Gothic", Arial, Helvetica, sans-serif',
      fontDisplay: "swap",
    },
    h3: {
      fontFamily: '"Letter Gothic", Arial, Helvetica, sans-serif',
      fontDisplay: "swap",
    },
    h4: {
      fontFamily: '"Letter Gothic", Arial, Helvetica, sans-serif',
      fontDisplay: "swap",
    },
    h5: {
      fontFamily: '"Letter Gothic", Arial, Helvetica, sans-serif',
      fontDisplay: "swap",
    },
    h6: {
      fontFamily: '"Letter Gothic", Arial, Helvetica, sans-serif',
      fontDisplay: "swap",
    },
    subtitle1: {
      fontFamily: '"Letter Gothic", Arial, Helvetica, sans-serif',
      fontDisplay: "swap",
    },
    subtitle2: {
      fontFamily: '"Letter Gothic", Arial, Helvetica, sans-serif',
      fontDisplay: "swap",
    },
    subtitle3: {
      fontFamily: '"Letter Gothic", Arial, Helvetica, sans-serif',
      fontDisplay: "swap",
    },
    body1: {
      fontFamily: '"Letter Gothic", Arial, Helvetica, sans-serif',
      fontDisplay: "swap",
    },
    body2: {
      fontFamily: '"Letter Gothic", Arial, Helvetica, sans-serif',
      fontDisplay: "swap",
    },
    button: {
      fontFamily: '"Letter Gothic", Arial, Helvetica, sans-serif',
      fontDisplay: "swap",
      fontWeight: "bold",
    },
    caption: {
      fontFamily: '"Letter Gothic", Arial, Helvetica, sans-serif',
      fontDisplay: "swap",
    },
    overline: {
      fontFamily: '"Letter Gothic", Arial, Helvetica, sans-serif',
      fontDisplay: "swap",
    },
  },
});
