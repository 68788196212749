import React, { Suspense } from "react";
import { Fade } from "react-awesome-reveal";
import { createTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import {
  GlobalCardsBox,
  GameNewsMobile,
  GameNewsDesktop,
} from "shared/constGlobal";

export default function GameNews() {
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Fade cascade damping={0.1}>
      <GlobalCardsBox id="news">
        <Suspense fallback={null}>
          {isMobile ? <GameNewsMobile /> : <GameNewsDesktop />}
        </Suspense>
      </GlobalCardsBox>
    </Fade>
  );
}
