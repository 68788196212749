import React, { useState, useEffect, Fragment } from "react";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import Login from "../components/login";
import { Grid, Stack } from "@mui/material";
import { GlobalCardsBox } from "shared/constGlobal";
import { CMSBox } from "shared/constDesktop";
import HeaderLogo from "images/headerLogo.webp";
import {
  HeaderImage,
  GlobalButton,
  GlobalTypography,
} from "shared/constGlobal";
import LogoutIcon from "@mui/icons-material/Logout";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { cmsTabs } from "shared/constGlobal";
import { CompanyBox, CompanyTab, cmsTabPanels } from "shared/constDesktop";

const MainPage = () => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const [value, setValue] = useState(
    localStorage.getItem("selectedTabCMS") || "1",
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("selectedTabCMS", newValue);
  };

  useEffect(() => {
    localStorage.setItem("selectedTabCMS", value);
  }, [value]);

  const handleLogIn = () => {
    setCookie("loggedIn", true, { path: "/cms", maxAge: 604800 });
  };

  const handleLogOut = () => {
    removeCookie("loggedIn", { path: "/cms", maxAge: 604800 });
  };

  const pageContent = (
    <GlobalCardsBox>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <CMSBox>
            <Stack direction="row" alignItems="center" spacing={2}>
              <HeaderImage
                src={HeaderLogo}
                width="100%"
                height="100%"
                alt="logo"
              />
              <GlobalTypography variant="h6">
                {"Website Content Management System"}
              </GlobalTypography>
            </Stack>
          </CMSBox>
        </Grid>
        <Grid item xs={2}>
          <CMSBox>
            <GlobalButton
              endIcon={<LogoutIcon />}
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleLogOut}
              sx={{
                height: 64,
              }}
            >
              Sign Out
            </GlobalButton>
          </CMSBox>
        </Grid>

        <Grid item xs={12}>
          <CMSBox>
            <TabContext value={value}>
              <CompanyBox>
                <TabList
                  onChange={handleChange}
                  aria-label="company tabs"
                  centered
                >
                  {cmsTabs.map((src) => (
                    <CompanyTab
                      label={src.label}
                      value={src.tabId}
                      key={src.tabId}
                      variant="h2"
                    />
                  ))}
                </TabList>
              </CompanyBox>
              {cmsTabPanels.map((src) => (
                <TabPanel value={src.tabPanelId} key={src.tabPanelId}>
                  {src.content}
                </TabPanel>
              ))}
            </TabContext>
          </CMSBox>
        </Grid>
      </Grid>
    </GlobalCardsBox>
  );

  return (
    <Fragment>
      <Helmet>
        <title>CMS | Seacorp Technologies</title>
      </Helmet>
      {cookies.loggedIn && pageContent}
      {!cookies.loggedIn && <Login onLogIn={handleLogIn} />}
    </Fragment>
  );
};

export default MainPage;
