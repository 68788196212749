import React, { Fragment, useState } from "react";
import { Typography, DialogContent, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PrivacyPolicy from "./privacyPolicy";
import { terms } from "shared/constGlobal";
import {
  GlobalTitle,
  GlobalDialog,
  GlobalIconButton,
  GlobalOverlayScrollbarsComponent,
  GlobalTypography,
  GlobalLink,
} from "shared/constGlobal";
//import { fetchContents } from "shared/fetchContents";

export default function TermsPolicy({ isTermsOpened, handleCloseTerms }) {
  /*const [content, setContent] = useState({
    content: {
      termspolicy: [
        {
          title: "",
          text: "",
        },
      ],
    },
  });*/

  //const termsPolicyContentEN = content?.content?.termspolicy[0];

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    handleCloseTerms(false);
  };

  const [isOpenPrivacy, setIsOpenPrivacy] = useState(false);
  const handlePrivacyPolicy = () => {
    setIsOpenPrivacy(!isOpenPrivacy);
  };

  return (
    <Fragment>
      {terms.map((src) => (
        <GlobalDialog open={isTermsOpened} onClose={handleClose} key={src.id}>
          <GlobalTitle variant="subtitle1" onClose={handleClose}>
            {src.title}
            <GlobalIconButton
              size="small"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </GlobalIconButton>
          </GlobalTitle>
          <GlobalOverlayScrollbarsComponent
            options={{
              scrollbars: {
                clickScrolling: true,
                theme: "os-theme-thick-blue",
              },
            }}
          >
            <DialogContent dividers>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
              >
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleOne}
                </GlobalTypography>
                <Typography variant="body2" gutterBottom>
                  {src.textOne}
                  <GlobalLink href="/">{src.textLinkOne}</GlobalLink>
                  {src.textTwo}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleTwo}
                </GlobalTypography>
                <Typography variant="subtitle3" gutterBottom>
                  {src.textThree}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textFour}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textFive}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textSix}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textSeven}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textEight}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {src.textNine}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleThree}
                </GlobalTypography>
                <Typography variant="body2" gutterBottom>
                  {src.textTen}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleFour}
                </GlobalTypography>
                <Typography variant="body2" gutterBottom>
                  {src.textEleven}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleFive}
                </GlobalTypography>
                <Typography variant="body2" gutterBottom>
                  {src.textTwelve}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleSix}
                </GlobalTypography>
                <Typography variant="body2" gutterBottom>
                  {src.textThirteen}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleSeven}
                </GlobalTypography>
                <Typography variant="body2" gutterBottom>
                  {src.textFourteen}
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleEight}
                </GlobalTypography>
                <Typography variant="body2" gutterBottom>
                  {src.textFifteen}
                  <GlobalLink onClick={handlePrivacyPolicy}>
                    {src.textLinkTwo}
                  </GlobalLink>
                  .
                </Typography>
                <GlobalTypography variant="subtitle2" component="div">
                  {src.subTitleNine}
                </GlobalTypography>
                <Typography variant="body2" gutterBottom>
                  {src.textSixteen}
                </Typography>
              </Stack>
            </DialogContent>
          </GlobalOverlayScrollbarsComponent>
        </GlobalDialog>
      ))}
      <PrivacyPolicy
        isPrivacyOpened={isOpenPrivacy}
        handleClosePrivacy={() => setIsOpenPrivacy(false)}
      />
    </Fragment>
  );
}
