import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AttentionSeeker } from "react-awesome-reveal";
import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import Typewriter from "typewriter-effect";
import {
  PageContainer,
  ErrorStack,
  ErrorBackground,
  ErrorTypography,
} from "shared/constGlobal";

export default function Error() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/error");
  }, [navigate]);

  return (
    <Fragment>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="author" content="Seacorp Technologies" />
        <meta
          name="title"
          content="404 - Page Not Found | Seacorp Technologies"
        />
        <title>404 - Page Not Found | Seacorp Technologies</title>
      </Helmet>
      <PageContainer maxWidth="xl">
        <Box display="flex" justifyContent="center" alignItems="center">
          <ErrorStack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <AttentionSeeker effect="headShake">
              <ErrorBackground alt="error" />
            </AttentionSeeker>

            <ErrorTypography variant="h2" gutterTop>
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .start()
                    .typeString("The page you're looking for doesn't exist...");
                }}
              />
            </ErrorTypography>
          </ErrorStack>
        </Box>
      </PageContainer>
    </Fragment>
  );
}
