import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { PageContainer } from "shared/constGlobal";
import CompanyInfo from "components/companyInfo";

export default function Company({ setOverflow }) {
  return (
    <Fragment>
      <Helmet>
        <meta charset="utf-8" />
        <link rel="canonical" href="https://www.seacorptech.com/company" />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="Seacorp Technologies, Video Games, Indie, Prospekt, Gunsmith, Kinetic Edge, Junkpunk, Blockville, Company, Steam, PC Games"
        />
        <meta name="author" content="Seacorp Technologies" />
        <meta name="title" content="Company | Seacorp Technologies" />
        <meta
          name="description"
          content="Company information for Seacorp Technologies."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.seacorptech.com/company" />
        <meta property="og:title" content="Company | Seacorp Technologies" />
        <meta
          property="og:description"
          content="Company information for Seacorp Technologies."
        />
        <meta property="og:image" content="/logo.webp" />

        <meta property="twitter:card" content="/logo.webp" />
        <meta
          property="twitter:url"
          content="https://www.seacorptech.com/company"
        />
        <meta
          property="twitter:title"
          content="Company | Seacorp Technologies"
        />
        <meta
          property="twitter:description"
          content="Company information for Seacorp Technologies."
        />
        <title>Company | Seacorp Technologies</title>
      </Helmet>
      <PageContainer maxWidth="xl">
        <CompanyInfo setOverflow={setOverflow} />
      </PageContainer>
    </Fragment>
  );
}
