import { useState, useEffect } from "react";
import { useOverlayScrollbars } from "overlayscrollbars-react";

export const Scrollbar = () => {
  const [overflow, setOverflow] = useState({ x: "scroll", y: "scroll" });

  const [initBodyOverlayScrollbars] = useOverlayScrollbars({
    defer: true,
    events: {
      initialized: true,
    },
    options: {
      scrollbars: {
        theme: "os-theme-thick-blue",
      },
      overflow,
    },
  });

  useEffect(() => {
    initBodyOverlayScrollbars(document.body);
  }, [initBodyOverlayScrollbars]);

  return { setOverflow, initBodyOverlayScrollbars };
};
